<template>
  <v-app id="inspire">
    <v-app-bar color="primary" app dark max-height="200">
      <v-app-bar-title>
        ＡＩレセチェッカー ダウンロード
      </v-app-bar-title>
    </v-app-bar>

    <v-container>
      <v-main>
        <v-tabs v-model="tab" grow class="mx-1">
          <v-tab v-for="item in tab_item" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <div v-if="tab == 1">
            <admin-news-list></admin-news-list>
          </div>

          <div v-else-if="tab == 2">
            <admin-data-list></admin-data-list>
          </div>

          <div v-else-if="tab == 3">
            <admin-program-list></admin-program-list>
          </div>

          <div v-else-if="tab == 4">
            <admin-env-list></admin-env-list>
          </div>

          <div v-else-if="tab == 5">
            <admin-tool-list></admin-tool-list>
          </div>

          <div v-else-if="tab == 6">
            <admin-etc-list></admin-etc-list>
          </div>

          <div v-else>
            <admin-user-list></admin-user-list>
          </div>
        </v-tabs-items>

      </v-main>
    </v-container>
  </v-app>
</template>
<script>
import AdminUserList from './AdminUserList.vue'
import AdminNewsList from './AdminNewsList.vue'
import AdminDataList from './AdminDataList.vue'
import AdminProgramList from './AdminProgramList.vue'
import AdminEnvList from './AdminEnvList.vue'
import AdminToolList from './AdminToolList.vue'
import AdminEtcList from './AdminEtcList.vue'

export default {
  name: 'admin_management',
  components: {
    AdminUserList,
    AdminNewsList,
    AdminDataList,
    AdminProgramList,
    AdminEnvList,
    AdminToolList,
    AdminEtcList,
  },
  data: () => ({
    tab: null,
    tab_item: ['ユーザ', 'お知らせ', 'データ','プログラム','環境','ツール','その他'],
    message: '',
  }),
  created() {
    if (sessionStorage.getItem('admin_id') == null || sessionStorage.getItem('token') == null) {
      this.$router.replace({ name: "admin_login" });
    }
  },
  methods: {
  },
}
</script>
