<template>
  <v-card min-height="190" min-width="1410" class="mt-5" id="program">
    <v-card-title class="mt-2 text-center justify-center pt-1">
      <div class="card_title">
        プログラム
      </div>
    </v-card-title>
    <v-progress-linear :indeterminate="downloading" color="black"></v-progress-linear>
    <v-card-text class="mt-2 pa-0">
      <v-treeview :items="items" open-all>
        <template v-slot:label="{ item }"> 
          <div v-if="item.children" class="contents">
            {{ item.name }}
          </div>
          <div v-else class="mb-5">
            {{ item.file_name }} 
            &nbsp;&nbsp;&nbsp;
            {{ calculation_size(item.file_size) }}
            <v-tooltip top v-if="item.file_size != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-5 mb-1 info" v-bind="attrs" v-on="on" @click="download_program(item)" :disabled="downloading">
                  <v-icon>file_download</v-icon>ダウンロード
                </v-btn>
              </template>
              <span>{{ item.file_name }} ダウンロード</span>
            </v-tooltip>
            <label v-if="item.status == 0">
              {{ item.comment }}
            </label>
          </div>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios'
export default {
  name: 'program_list',
  props: ['program'],
  data: () => ({
    items: [],
    downloading: false,
  }),
  created() {
    this.fetch_program();
  },
  methods: {
    fetch_program() {
      let all = 0;
      let patch = 0;
      this.program.map(e => {
        if (e.status == 1) {
          if (all == 0) {
            this.items[0] = {
              id:0, name: "一括", children:[]
            }
            all ++;
          }
          this.items[0].children.push(e)
        } else {
          if (patch == 0) {
            this.items[1] = {
              id:1, name: "パッチ", children:[]
            }
            patch ++;
          }
          this.items[1].children.push(e)
        }
      })
    },
    download_program(item) {
      this.downloading = true;
      const token = sessionStorage.getItem('token');
      const data = {
        access: token,
        fileName: item.file_name,
        select: 'PROGRAM',
        fileSize: item.file_size,
      }
      const config = {
        responseType: 'blob',
        headers: {
          "Content-Type": "application/zip"
        },
      }
      axios.post('/app/download.php', data, config).then(function(response) {
        if (response.data.type == "text/html") {
          alert("認証に失敗しました 再ログインしてください。")
          sessionStorage.clear();
          this.$router.replace({ name: "login" });
          return;
        } 
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.file_name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.downloading = false;
      }.bind(this))
    },
    calculation_size(file) {
      let size = 0;
      if (file != 0) {
        if (file > 1024*1024) {
          size = Math.round((file/1024)/1024) + " MB";
        } else if (file > 1024) {
          size = Math.round(file/1024) + " KB";
        } else {
          size = file + " Byte";
        }
      } else {
        return "ファイルを確認できません。"
      }
      return size;
    },
  }
}
</script>
