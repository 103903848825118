<template>
  <v-app id="inspire" class="download">
    <v-app-bar color="primary" app dark max-height="200">
      <v-app-bar-title>
        ＡＩレセチェッカー ダウンロード
      </v-app-bar-title>
    </v-app-bar>

    <v-main class="pt-1 header">
      <v-container class="pt-0 mx-0">
        <v-row class="mt-5" >
          <div>
            <v-img
              class="mx-5 mt-0"
              min-height="198"
              min-width="257"
              src="@/assets/medical_logo.png"
            ></v-img>
          </div>

          <div style="position: absolute; left:300px">
            <news-list
              :news="news"
            ></news-list>
          </div>

          <div style="position: absolute; left:974px">
            <action
              :userId="user_name"
              @scroll="scroll"
            ></action>
            <li class="ma-2" style="list-style: none; color: red;">
              {{ message }}
            </li>
          </div>
        </v-row>
      </v-container>
    </v-main>

    <v-main class="pa-0 file">
      <v-container class="pa-0 mx-0">
        <v-row class="mt-0 pl-4">
          <v-col cols="9">
            <data-list
              v-if="show_data"
              :data="data"
            ></data-list>
            <program-list
              v-if="show_program"
              :program="program"
            ></program-list>
            <env-list
              v-if="show_env"
              :env="env"
            ></env-list>
            <tool-list
              v-if="show_tool"
              :tool="tool"
            ></tool-list>
            <etc-list
              v-if="show_etc"
              :etc="etc"
            ></etc-list>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <div class="empty_space"></div>
  </v-app>
</template>
<style lang="css">
.empty_space {
  height: 550px !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: black !important;
  font-size: 18px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: black !important;
  font-size: 15px !important;
}
</style>
<script>
import Action from './Action.vue'
import NewsList from './NewsList.vue'
import DataList from './DataList.vue';
import ProgramList from './ProgramList.vue';
import EnvList from './EnvList.vue';
import ToolList from './ToolList.vue';
import EtcList from './EtcList.vue';
import axios from 'axios'

export default {
  name: 'download',
  components: {
    Action,
    NewsList,
    DataList,
    ProgramList,
    EnvList,
    ToolList,
    EtcList,
  },
  data: () => ({
    user_id: '',
    user_name: '',
    data: [],
    program: [],
    env: [],
    tool: [],
    etc: [],
    news: '',
    show_data:false,
    show_program:false,
    show_env:false,
    show_tool:false,
    show_etc:false,
    message: '',
    public_list: [],
  }),
  created() {
    if (sessionStorage.getItem('user_id') == null || sessionStorage.getItem('token') == null) {
      this.$router.replace({ name: "login" });
    }
    this.user_id = sessionStorage.getItem('user_id');
    this.user_name = sessionStorage.getItem('user_name');
    this.get_public_list();
  },
  methods: {
    async get_public_list() {
      const data = {
        userId: this.user_id,
        type: "get"
      }
      await axios.post('/app/admin/settingController.php', data).then(function(response) {
        if(response.data.status == true && response.data.file) {
          this.public_list = JSON.parse(response.data.file.file);
        } else {
          this.public_list = [{type:''}]
        }
        this.fetch_list();
      }.bind(this))
    },
    async fetch_list() {
      var data = {
        list: [],
        type: 'news'
      };
      axios.post('/app/fileController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.news = response.data.news[0]
        } else {
          this.message = response.data.message;
        }
      }.bind(this))

      data = {
        list :this.public_list.filter(e => e.type == "その他"),
        type: 'etc'
      }
      if (data['list'].length != 0) {
        axios.post('/app/fileController.php', data).then(function(response) {
          if(response.data.status == true) {
            this.etc = response.data.etc;
            this.show_etc = true;
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      }

      data = {
        list :this.public_list.filter(e => e.type == "ツール"),
        type: 'tool'
      }
      if (data['list'].length != 0) {
        axios.post('/app/fileController.php', data).then(function(response) {
          if(response.data.status == true) {
            this.tool = response.data.tool;
            this.show_tool = true;
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      }

      data = {
        list :this.public_list.filter(e => e.type == "環境"),
        type: 'env'
      }
      if (data['list'].length != 0) {
        axios.post('/app/fileController.php', data).then(function(response) {
          if(response.data.status == true) {
            this.env = response.data.env;
            this.show_env = true;
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      }

      data = {
        list :this.public_list.filter(e => e.type == "プログラム"),
        type: 'program'
      }
      if (data['list'].length != 0) {
        axios.post('/app/fileController.php', data).then(function(response) {
          if(response.data.status == true) {
            this.program = response.data.program;
            this.show_program = true;
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      }

      data = {
        list :this.public_list.filter(e => e.type == "データ"),
        type: 'data'
      }
      if (data['list'].length != 0) {
        axios.post('/app/fileController.php', data).then(function(response) {
          if(response.data.status == true) {
            this.data = response.data.data;
            this.show_data = true;
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      }
    },
    scroll(item) {
      let location = document.getElementById(item).offsetTop;
      window.scrollTo({top:location - 24, behavior:'smooth'});
    }
  },
}
</script>
