<template>
<v-dialog persistent v-model="dialog" width="70%">
  <v-container class="pa-0" fluid>
    <v-card>
      <v-card-title class="pt-3 card_edit_title">
        {{ user_id }} ー {{ user_name }}
        <v-spacer></v-spacer>
        <v-btn class="success mx-2" @click="regist">登録</v-btn>
        <v-btn class="error mx-2" @click="close">キャンセル</v-btn>
      </v-card-title>
      <v-card-text>
        <v-col cols="4">
          <v-select
            v-model="type_filter"
            @change="filter_selected"
            :items="type_list"
            :menu-props="{ maxHeight: '400'}"
            label="タイプ"
            hide-details
          ></v-select>
        </v-col>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="target_items"
          :footer-props="footer"
          :options.sync="options"
          item-key="file_name"
          no-data-text="データがありません"
          height="600"
          show-select
        >
          <template v-slot:item.action="{ item }">  
            <v-btn class="error mx-2" @click="remove(item.file_name)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</v-dialog>
</template>
<script>
import axios from 'axios'
export default {
  name: 'admin_file_list',
  props: ['user_id', 'user_name'],
  data: () => ({
    target_items: [],
    items: [],
    data: [],
    program: [],
    env: [],
    tool: [],
    etc: [],
    headers: [
      {text: "ファイル名", value: "file_name", width:"70%"},
      {text: "アップロード日", value: "upload_date_at", width:"15%"},
      {text: "種類", value: "type", width:"15%"},
    ],
    footer : {
      itemsPerPageText:"1ページあたりの行数",
      itemsPerPageOptions:[5,10,25,-1]
    },
    options : {
      page:1,
      itemsPerPage: 10,
    },
    selected: [],
    message: '',
    type_filter: '',
    type_list: [
      '','データ','プログラム','環境','ツール','その他'
    ],
    dialog: false,
  }),
  created() {
    this.dialog = true;
    this.get_public_list();
    if (sessionStorage.getItem('file_list') == null || sessionStorage.getItem('file_list') == '') {
      this.fetch_data();
    } else {
      this.items = JSON.parse(sessionStorage.getItem('file_list'));
      this.filter_selected()
    }
  },
  computed: {
  },
  methods: {
    async get_public_list() {
      const data = {
        userId: this.user_id,
        type: 'get'
      }
      await axios.post('/app/admin/settingController.php', data).then(function(response) {
        if(response.data.status == true && response.data.file) {
          this.selected = JSON.parse(response.data.file.file);
        }
      }.bind(this))
    },
    async fetch_data() {
      const data = {
        type: "get"
      }
      await axios.post('/app/admin/dataController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.data = response.data.data
          this.data.map(e => {
            this.items.push({
              file_name: e.file_name,
              upload_date_at: e.upload_date_at,
              type: "データ"
            })
          })
        } else {
          this.message = response.data.message;
        }
      }.bind(this))

      await axios.post('/app/admin/programController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.program = response.data.program
          this.program.map(e => {
            this.items.push({
              file_name: e.file_name,
              upload_date_at: e.upload_date_at,
              type: "プログラム"
            })
          })
        } else {
          this.message = response.data.message;
        }
      }.bind(this))

      await axios.post('/app/admin/envController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.env = response.data.env
          this.env.map(e => {
            this.items.push({
              file_name: e.file_name,
              upload_date_at: e.upload_date_at,
              type: "環境"
            })
          })
        } else {
          this.message = response.data.message;
        }
      }.bind(this))

      await axios.post('/app/admin/toolController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.tool = response.data.tool
          this.tool.map(e => {
            this.items.push({
              file_name: e.file_name,
              upload_date_at: e.upload_date_at,
              type: "ツール"
            })
          })
        } else {
          this.message = response.data.message;
        }
      }.bind(this))

      await axios.post('/app/admin/etcController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.etc = response.data.etc
          this.etc.map(e => {
            this.items.push({
              file_name: e.file_name,
              upload_date_at: e.upload_date_at,
              type: "その他"
            })
          })
        } else {
          this.message = response.data.message;
        }
      }.bind(this))
      sessionStorage.setItem('file_list', JSON.stringify(this.items));
      this.filter_selected()
    },
    async regist() {
      var data = {
          userId: this.user_id,
          file: '',
          type: 'set'
      }
      if (this.selected.length != 0) {
        const exist_item = this.selected.filter(e => {
          if (this.items.find(obj => obj.file_name == e.file_name) != undefined) {
            return {
              file_name: e.file_name,
              type: e.type
            }
          }
        })
        data['file'] = JSON.stringify(exist_item);
      }
      await axios.post('/app/admin/settingController.php', data)
        .then(function(response) {
          if (response.data.status == false) {
            this.message = response.data.message;
          }
          this.close();
        }.bind(this))
    },
    filter_selected() {
      this.target_items = this.items
        .filter(item => this.type_filter === '' ? true 
          : (!item.type ? false : item.type.includes(this.type_filter)))
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  }
}
</script>
