<template>
<div>
<v-card class="mx-1 my-2">
  <v-card-title class="pt-1">
    <div class="card_title mx-2">
      ユーザー
    </div>
    <v-spacer></v-spacer>
    <v-btn class="success mx-2" small @click="add"><v-icon>add</v-icon>登録</v-btn>
  </v-card-title>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="footer"
      :options.sync="options"
      no-data-text="データがありません"
    >
      <template v-slot:item.status="{ item }">  
        {{ item.status == 1 ? '有効' : '無効' }}
      </template>
      <template v-slot:item.action="{ item }">  
        <v-btn class="info mx-2" small @click="edit(item)">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn class="success mx-2" small @click="file(item)">
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn class="error mx-2" small @click="remove(item.user_id)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
<v-dialog persistent v-model="edit_show" width="70%">
  <v-container class="pa-0" fluid>
    <v-card>
      <v-card-title class="pt-3 card_edit_title">
        ユーザー管理
        <v-spacer></v-spacer>
        <v-btn class="success mx-2" v-if="action == 1" @click="regist">登録</v-btn>
        <v-btn class="success mx-2" v-else @click="accept">編集</v-btn>
        <v-btn class="error mx-2" @click="close">キャンセル</v-btn>
      </v-card-title>
      <v-card-text>
        <li class="ma-2" style="list-style: none; color: red;">
          {{ edit_message }}
        </li>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-text-field
              v-model="edit_item.user_name"
              label="ユーザ名"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="edit_item.user_id"
              label="ユーザID"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="edit_item.password"
              :type="password_show ? 'text' : 'password'"
              label="パスワード"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="confirm_password"
              :type="password_show ? 'text' : 'password'"
              :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="password_show = !password_show"
              label="パスワード確認"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="status_item"
              v-model="edit_item.status"
              label="状態"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="calandar_show"
              :close-on-content-click="false"
              offset-y
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="edit_item.end_date_at"
                  clearable
                  label="利用終了日"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="jp-ja"
                v-model="edit_item.end_date_at"
                no-title
                @change="calandar_show = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</v-dialog>
<admin-file-list
  v-if="file_show"
  @close="file_close"
  :user_id="user_id"
  :user_name="user_name"
></admin-file-list>
</div>
</template>
<script>
import AdminFileList from './AdminFileList.vue'
import axios from 'axios'
export default {
  name: 'admin_user_list',
  components: { 
    AdminFileList,
  },
  data: () => ({
    items: [],
    headers: [
      {text: "番号", value: "_id"},
      {text: "ユーザ名", value: "user_name"},
      {text: "ユーザID", value: "user_id"},
      {text: "状態", value: "status"},
      {text: "登録日", value: "start_date_at"},
      {text: "利用終了日", value: "end_date_at"},
      {text: "最後の接続日", value: "last_date_at"},
      {text: "", value: "action", sortable:false},
    ],
    footer : {
      itemsPerPageText:"1ページあたりの行数",
      itemsPerPageOptions:[5,10,25,-1]
    },
    options : {
      page:1,
      itemsPerPage: 10,
    },
    edit_message: '',
    action: 0,
    edit_item: '',
    edit_show: false,
    confirm_password: '',
    password_show: false,
    status_item: [
      {text:'無効', value:"0"},
      {text:'有効', value:"1"}
    ],
    user_id: '',
    user_name: '',
    file_show: false,
    calandar_show: false,
  }),
  created() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      const data = {
        type: "get"
      }
      axios.post('/app/admin/userController.php', data).then(function(response) {
        if (response.data.status == true) {
          this.items = response.data.user
        } else {
          this.items = []
        }
      }.bind(this))
    },
    add() {
      this.edit_item = {
        user_id : '',
        user_name : '',
        password : '',
        status : "0",
        end_date_at : '',
      }
      this.confirm_password = '',
      this.action = 1
      this.edit_show = true
    },
    edit(item) {
      const lodash = require("lodash")
      this.edit_item = lodash.cloneDeep(item)
      this.edit_item.password = '';
      this.confirm_password = '',
      this.action = 2
      this.edit_show = true;
    },
    file(item) {
      this.user_id = item.user_id
      this.user_name = item.user_name
      this.file_show = true;
    },
    file_close() {
      this.user_id = '';
      this.user_name = '';
      this.file_show = false;
    },
    async remove(item) {
      const data = {
        user_id: item,
        type: "remove"
      }
      await axios.post('/app/admin/userController.php', data)
        .then(function(response) {
          if (response.data.status == false) {
            this.message = response.data.message;
          }
          this.fetch_data()
        }.bind(this))
    },
    async regist() {
      if (this.edit_item.user_name == '') {
        this.edit_message = "ユーザ名を確認してください。"
        return;
      }

      if (this.edit_item.user_id == '') {
        this.edit_message = "ユーザIDを確認してください。"
        return;
      }

      if ((this.edit_item.password != this.confirm_password) || !(this.edit_item.password != '' || this.confirm_password != '')) {
        this.edit_message = "パスワードを確認してください。"
        return;
      }

      if (this.edit_item.end_date_at == '') {
        this.edit_message = "利用終了日を確認してください。"
        return;
      }

      let data = this.edit_item
      data['type'] = "add"
      await axios.post('/app/admin/userController.php', data)
        .then(function(response) {
          if (response.data.status == false) {
            this.edit_message = response.data.message;
          }
          this.edit_item = '';
          this.confirm_password = '',
          this.edit_message = '',
          this.action = 0;
          this.edit_show = false;
          this.fetch_data()
        }.bind(this))
    },
    async accept() {
      if ((this.edit_item.password != this.confirm_password)) {
        return;
      }
      let data = this.edit_item
      data['type'] = "edit"
      await axios.post('/app/admin/userController.php', data)
        .then(function(response) {
          if (response.data.status == false) {
            this.message = response.data.message;
          }
          this.edit_item = '';
          this.confirm_password = '',
          this.edit_message = '',
          this.action = 0;
          this.edit_show = false;
          this.fetch_data()
        }.bind(this))
    },
    close() {
      this.edit_message = '',
      this.edit_item = '';
      this.edit_show = false;
    },
  }
}
</script>
