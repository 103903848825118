<template>
  <v-card min-height="200" width="660">
    <v-card-title class="text-center justify-center pt-1">
      <div class="card_title">
        案内
      </div>
    </v-card-title>

    <v-card-text class="mt-2" style="color:black">
      <div class="card_text">
        ファイルをダウンロードしてＰＣのデータを最新版に更新してください。手順につきましては、
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a href="./AIRC-OPMNL-0001.pdf" v-bind="attrs" v-on="on" target="_blank" ondragstart="return false" >サポートマニュアル</a>
          </template>
          <span>ドキュメントを開く</span>
        </v-tooltip>
         をご参照ください。
      </div>
      <li class="card_text mt-4" style="font-weight:bold">
        {{ news.news_date }}
      </li>
      <div class="card_text mt-2">
        <ul>{{ news.news_text }}</ul>  
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'news',
    props: ['news'],
    data: () => ({
    }),
    methods: {
    },
  }
</script>
