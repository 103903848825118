<template>
  <v-app id="inspire" dark>
    <v-main>
      <v-container fluid>
        <v-layout justify-center>
          <v-card width="500" elevation="12" outlined>
            <v-card-title class="text-center justify-center card_edit_title">
              ＡＩレセチェッカー ダウンロード
            </v-card-title>
            <v-card-text class="pb-0 mt-4">
              <li class="ma-2" style="list-style: none; color: red;">
                {{ message }}
              </li>
                <div class="login_label">
                  <v-icon color="black" size="30">person</v-icon> 管理者ID
                </div>
                <v-text-field
                  class="px-1 login_input"
                  id="id"
                  name="id"
                  ref="id"
                  v-model="admin_id"
                  placeholder="管理者ID"
                  @keydown.enter="enter(1)"
                ></v-text-field> 
              <div class="login_label pt-2">
                <v-icon color="black" size="30">lock</v-icon> 管理者パスワード
              </div>
              <v-text-field
                class="px-1 login_input"
                id="password"
                name="password"
                ref="password"
                v-model="admin_password"
                :type="show ? 'text' : 'password'"
                placeholder="管理者パスワード"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                @keydown.enter="enter(2)"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" color="indigo darken-4" dark large @click="login" width="100">
                ログイン
              </v-btn>
              <v-btn class="ma-2" color="grey darken-1" dark large @click="clear" width="100">
                キャンセル
              </v-btn >
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'admin_login',
    data: () => ({
      admin_id:'',
      admin_password:'',
      message: '',
      show:false,
    }),
    created() {
    },
    methods: {
      enter(index) {
        if (index == 1) {
          this.$refs.password.focus();
        } else {
          this.login()
        }
      },
      login() {
        const url = "/app/admin/adminLogin.php";
        const data = {
          adminId: this.admin_id,
          adminPassword: this.admin_password
        }
        axios.post(url, data).then(function(response) {
          if(response.data.status == true) {
            sessionStorage.setItem('admin_id', this.admin_id);
            sessionStorage.setItem('token', response.data.authorization);
            this.$router.push({ name: 'admin_management' });
          } else {
            this.message = response.data.message;
          }
        }.bind(this))
      },
      clear() {
        this.admin_id = '';
        this.admin_password = '';
      },
    },
  }
</script>
