import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.prototype.$http = axios

import Login from '@/components/Login.vue'
import Download from '@/components/Download.vue'
import AdminLogin from '@/components/AdminLogin.vue'
import AdminManagement from '@/components/AdminManagement.vue'
import store from './store/index'

const routes = [
  //redirect
  {path:'/', redirect:{name: "login"}},
  {path:'/admin', redirect:{name: "admin_login"}},

  // user
  {path:'/login', name: "login", component:Login},
  {path:'/download', name: "download", component:Download},

  // admin
  {path:'/admin/login', name: "admin_login", component:AdminLogin},
  {path:'/admin/management', name: "admin_management", component:AdminManagement},
  
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: routes
});


new Vue({
  vuetify,
  router: router,
  store,
  render: h => h(App)
}).$mount('#app')
