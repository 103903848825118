import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authorization:'',
    user_id:'',               // ログインユーザ情報
    file_list:[],
  },
  getters: {
    authorization(state){
      return state.authorization
    },
    user_id(state){
      return state.user_id
    },
    file_list(state){
      return state.file_list
    },
  },
  mutations: {
    set_authorization(state,result){
      state.authorization = result
    },
    set_user(state,result){
      state.user_id = result
    },
    set_file_list(state,result){
      state.file_list = result
    },
  },
  actions: {
  },
  modules: {
  }
})
