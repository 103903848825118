<template>
<div>
<v-card class="mx-1 my-2">
  <v-card-title class="pt-1">
    <div class="card_title mx-2">
      データ
    </div>
    <v-spacer></v-spacer>
    <v-btn class="success mx-2" small @click="add"><v-icon>add</v-icon>登録</v-btn>
  </v-card-title>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-sort
      no-data-text="データがありません"
    >
      <template v-slot:item.status="{ item }">  
        {{ item.status == 1 ? '一括' : 'パッチ' }}
      </template>
      <template v-slot:item.etc="{ item }">  
        {{ `${item.etc == 1 ? '有' : '無'}` }}
      </template>
      <template v-slot:item.action="{ item }">  
        <v-btn class="error mx-2" small @click="remove(item.file_name)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
<v-dialog persistent v-model="edit_show" width="70%">
  <v-container class="pa-0" fluid>
    <v-card>
      <v-card-title class="pt-3 card_edit_title">
        データ管理
        <v-spacer></v-spacer>
        <v-btn class="success mx-2" @click="regist">登録</v-btn>
        <v-btn class="error mx-2" @click="close">キャンセル</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-text-field
              v-model="edit_item.file_name"
              label="ファイル名"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="status_item"
              v-model="edit_item.status"
              label="状態"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="etc_item"
              v-model="edit_item.etc"
              label="その他"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.tensu"
              label="点数表"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.shobyome"
              label="傷病名"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.ikotaio"
              label="移行対応"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.comment"
              label="コメント"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.comment_kanren"
              label="コメント関連"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.shinryokoi"
              label="診療行為"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.byoto_code"
              label="病棟コード"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.tokutekizai"
              label="特定器材"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.iyakuhin"
              label="医薬品"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="edit_item.shushokugo"
              label="修飾語"
            ></v-text-field>
          </v-col>

          <v-col cols="6"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</v-dialog>
</div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'admin_data_list',
  data: () => ({
    items: [],
    headers: [
      {text: "ファイル名", value: "file_name"},
      {text: "区分", value: "status"},
      {text: "アップロード日", value: "upload_date_at"},
      {text: "点数表", value: "tensu"},
      {text: "傷病名", value: "shobyome"},
      {text: "移行対応", value: "ikotaio"},
      {text: "コメント", value: "comment"},
      {text: "コメント関連", value: "comment_kanren"},
      {text: "診療行為", value: "shinryokoi"},
      {text: "病棟コード", value: "byoto_code"},
      {text: "特定器材", value: "tokutekizai"},
      {text: "医薬品", value: "iyakuhin"},
      {text: "修飾語", value: "shushokugo"},
      {text: "その他", value: "etc"},
      {text: "", value: "action"}
    ],
    edit_item: {},
    edit_show: false,
    status_item: [
      {text:'パッチ', value:0},
      {text:'一括', value:1}
    ],
    etc_item: [
      {text:'無', value:0},
      {text:'有', value:1}
    ],
  }),
  created() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      const data = {
        type: "get"
      }
      axios.post('/app/admin/dataController.php', data).then(function(response) {
        if (response.data.status == true) {
          this.items = response.data.data
        } else {
          this.items = []
        }
      }.bind(this))
    },
    add() {
      if (this.items.length != 0) {
        const index = this.items.length - 1
        this.edit_item = {
          file_name : '',
          status : '',
          tensu : this.items[index].tensu ? this.items[index].tensu : '',
          shobyome : this.items[index].shobyome ? this.items[index].shobyome : '',
          ikotaio : this.items[index].ikotaio ? this.items[index].ikotaio : '',
          comment : this.items[index].comment ? this.items[index].comment : '',
          comment_kanren : this.items[index].comment_kanren ? this.items[index].comment_kanren : '',
          shinryokoi : this.items[index].shinryokoi ? this.items[index].shinryokoi : '',
          byoto_code : this.items[index].byoto_code ? this.items[index].byoto_code : '',
          tokutekizai : this.items[index].tokutekizai ? this.items[index].tokutekizai : '',
          iyakuhin : this.items[index].iyakuhin ? this.items[index].iyakuhin : '',
          shushokugo : this.items[index].shushokugo ? this.items[index].shushokugo : '',
          etc : '',
        }
      } else {
        this.edit_item = {
          file_name: '',
          status: '',
          upload_date_at: '',
          tensu: '',
          shobyome: '',
          ikotaio: '',
          comment: '',
          comment_kanren: '',
          shinryokoi: '',
          byoto_code: '',
          tokutekizai: '',
          iyakuhin: '',
          shushokugo: '',
          etc: '',
        }
      }
      this.edit_show = true
    },
    async remove(item) {
      const data = {
        file_name: item,
        type: "remove"
      }
      await axios.post('/app/admin/dataController.php', data)
        .then(function() {
          sessionStorage.setItem('file_list', '');
          this.fetch_data()
        }.bind(this))
    },
    async regist() {
      if ((this.edit_item.file_name == '') && (this.edit_item.status == '')) {
        return;
      }
      let data = this.edit_item
      data['type'] = "add"
      await axios.post('/app/admin/dataController.php', data)
        .then(function() {
          this.edit_item = '';
          this.edit_show = false;
          sessionStorage.setItem('file_list', '');
          this.fetch_data()
        }.bind(this))
    },
    close() {
      this.edit_item = '';
      this.edit_show = false;
    },
  }
}
</script>
