<template>
<div>
<v-card class="mx-1 my-2">
  <v-card-title class="pt-1">
    <div class="card_title mx-2">
      お知らせ
    </div>
    <v-spacer></v-spacer>
    <v-btn class="success mx-2" small @click="add"><v-icon>add</v-icon>登録</v-btn>
  </v-card-title>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-sort
      no-data-text="データがありません"
    >
      <template v-slot:item.action="{ item }">  
        <v-btn class="error mx-2" small @click="remove(item.news_date)">
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
<v-dialog persistent v-model="edit_show" width="70%">
  <v-container class="pa-0" fluid>
    <v-card>
      <v-card-title class="pt-3 card_edit_title">
        お知らせ管理
        <v-spacer></v-spacer>
        <v-btn class="success mx-2" @click="regist">登録</v-btn>
        <v-btn class="error mx-2" @click="close">キャンセル</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="2">
            <v-menu
              v-model="calandar_show"
              :close-on-content-click="false"
              offset-y
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="edit_item.news_date"
                  clearable
                  label="日付"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="jp-ja"
                v-model="edit_item.news_date"
                no-title
                @change="calandar_show = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="edit_item.news_text"
              label="内容"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</v-dialog>
</div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'admin_news_list',
  data: () => ({
    items: [],
    headers: [
      {text: "日付", value: "news_date"},
      {text: "内容", value: "news_text"},
      {text: "", value: "action"},
    ],
    message: '',
    edit_item: '',
    edit_show: false,
    calandar_show: false,
  }),
  created() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      const data = {
        type: "get"
      }
      axios.post('/app/admin/newsController.php', data).then(function(response) {
        if(response.data.status == true) {
          this.items = response.data.news
        } else {
          this.items = []
        }
      }.bind(this))
    },
    add() {
      this.edit_item = {
        news_date : '',
        news_text : '',
      }
      this.edit_show = true
    },
    async remove(item) {
      const data = {
        news_date: item,
        type: "remove"
      }
      await axios.post('/app/admin/newsController.php', data)
        .then(function() {
          this.fetch_data()
        }.bind(this))
    },
    async regist() {
      if ((this.edit_item.news_date == '') || (this.edit_item.news_text == '')) {
        return;
      }
      let data = this.edit_item
      data['type'] = "add"
      await axios.post('/app/admin/newsController.php', data)
        .then(function() {
          this.edit_item = '';
          this.edit_show = false;
          this.fetch_data()
        }.bind(this))
    },
    close() {
      this.edit_item = '';
      this.edit_show = false;
    },
  }
}
</script>
