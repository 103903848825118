<template>
  <v-card width="452">
    <v-card-title class="text-center justify-center pt-1">
      <div class="card_title">
        {{ userId }}
      </div>
    </v-card-title>

    <v-card-text class="mt-4 pb-2" style="color:black">
      <div class="card_title">
        画面スクロールボタン
      </div>
      <br>
      環境、プログラム、データ順に実行してください。
    </v-card-text>
    
    <v-card-actions class="pt-0 text-center justify-center">
      <v-btn value="list" @click="click(list.value)" color="info" class="ma-2 toggle_button" v-for="list in item" v-bind:key="list.text" >
        {{list.text}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'action',
  props: ['userId'],
  data: () => ({
    item: [
      {text:'データ', value:'data'}, 
      {text:'プログラム', value:'program'},
      {text:'環境', value:'env'},
      {text:'ツール', value:'tool'}
    ],
  }),
  methods: {
    click(item) {
      this.$emit('scroll', item);
    },
  },
}
</script>
